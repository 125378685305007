<template>
  <div>
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div class="container h-100 bg-first">
        <b-row>
          <b-col md="12">
            <article class="mt-2 lottery-search pb-2 pt-2">
              <div class="text-center">
                <h1>
                  <i class="fa-solid fa-magnifying-glass" /> ค้นหาสลากฯ ในเล่มที่ท่านสนใจ
                </h1>
              </div>
              <div class="container-sm mt-1">
                <h5 class="font-weight-bolder">
                  งวดวันที่ {{ lotteryset[0] ? lotteryset[0].roundth : 'ยังไม่ได้เปิดรอบ' }}
                </h5>
                <div class="d-flex justify-content-center">
                  <otp-input
                    v-if="renderComponent"
                    v-model="input_numlot"
                    :digits="6"
                    @on-complete="onCompleteHandler"
                    @on-changed="onChangedHandler"
                    @on-paste="onPasteHandler"
                  >
                    <template #errorMessage>
                      There is an error
                    </template>
                  </otp-input>

                </div>

                <b-row class="mt-3">
                  <b-col cols="4">
                    <b-button
                      block
                      size="lg"
                      variant="outline-primary"
                      class="btn-clear"
                      @click="cleartext()"
                    >
                      เคลียร์
                    </b-button>
                  </b-col>
                  <b-col cols="8">
                    <b-button
                      block
                      size="lg"
                      class="btn-search"
                      @click="getLotterySet()"
                    >
                      ค้นหา
                    </b-button>
                  </b-col>
                </b-row>

              </div>
            </article>
          </b-col>
        </b-row>

        <!-- <b-card class="mt-4">
        <div class="d-flex align-items-center justify-content-center">
          <span>เลือกชุดหวย :</span>
          <b-button class="ml-1 btn-clear">
            1 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            2 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            3 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            4 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            5 ใบ
          </b-button>
        </div>
      </b-card> -->

        <div>
          <div class="mt-2">
            <h3 class="text-white text-center">
              --ผลการค้นหา--
            </h3>
          </div>

          <b-row v-if="lotteryset.length > 0">
            <b-col
              v-for="item in lotteryset"
              :key="item._id"
              lg="4"
              md="6"
              cols="12"
              class="mt-2"
            >

              <div class="lotto-items">

                <div class="card-lotto-set">
                  <div class="img-lotto">
                    <img
                      :src="require('@/assets/images/lottery/Logo_about.png')"
                      alt="logo"
                    >
                  </div>

                  <div class="detail-lotto">
                    <div class="d-flex justify-content-between bd">
                      <h5>สลากเล่มที่ {{ item.set_no }}</h5>
                      <h5 class="pink">
                        <small>สลาก</small> 100 <small>ใบ</small>
                      </h5>
                    </div>
                    <div class="d-flex justify-content-between">
                      <span>งวดวันที่ {{ item.roundth }}</span>
                    <!-- <span>สลาก 100 ใบ</span> -->
                    </div>
                  </div>
                </div>

                <div>
                  <b-button
                    block
                    size="lg"
                    variant="primary"
                    class="btn-add mt-1"
                    @click="detail(item.set_no)"
                  >
                    <i class="fas fa-shopping-basket" /> ดูรายละเอียดสลาก ในเล่มนี้
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="mt-1">
            <b-pagination
              v-model="currentPage"
              class="mt-1"
              hide-goto-end-buttons
              :total-rows="totalRows"
              first-number
              last-number
              align="center"
              @input="getLotterySet()"
            />
            <br><br><br>
          </div>
        </div>

        <div class="z-10">
          <ThisFooter />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BPagination,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import OtpInput from '../components/OtpInput.vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    OtpInput,
    BPagination,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      renderComponent: true,
      input_numlot: '',
      slide: 0,
      sliding: null,
      lotteryset: [],
      totalRows: 1,
      currentPage: 1,
      show: false,
    }
  },
  mounted() {
    localStorage.removeItem('otp')
    localStorage.removeItem('type')
    this.getLotterySet()
  },
  methods: {
    detail(item) {
      this.$router.push({ name: 'lottery-set-agent', params: { id: item } })
    },
    getLotterySet() {
      this.show = true
      const Obj = {
        page: this.currentPage,
        lottonumber: JSON.parse(localStorage.getItem('otp')),
        lottotype: JSON.parse(localStorage.getItem('type')),
      }
      this.$http
        .post('https://uatapi.sabaideelotto.com/api/lottery/getlotteryset', Obj)
        .then(response => {
          this.show = false
          this.lotteryset = response.data.lottery
          this.totalRows = response.data.total
        })
        .catch(error => {
          this.show = false
          console.log(error)
        })
    },
    cleartext() {
      this.input_numlot = ''
      localStorage.removeItem('otp')
      localStorage.removeItem('type')
      this.renderComponent = false
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true
      })
      this.getLotterySet()
    },
  },
}
</script>

<style scoped>
</style>
